import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WS } from '../../../core/services/ws.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-product-modal',
  templateUrl: './edit-product-modal.component.html',
  styleUrls: ['./edit-product-modal.component.scss']
})
export class EditProductModalComponent implements OnInit {

  editPostForm: FormGroup;
  productId: number;
  product: any;
  form: FormGroup;
  submitEditForm: boolean = false;
  previewUrlProduct: any;
  fileDataProduct: File = null;
  event: EventEmitter<any> = new EventEmitter();

  constructor(public modalRef: NgbActiveModal, private modalService: NgbModal, public ws: WS, public formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      marque: ['', [Validators.required]],
      reference: ['', []],
      link: ['', [Validators.required, Validators.pattern('^(https?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.)[a-zA-Z0-9-]+(\\.[a-zA-Z]{2,})(\\/[-a-zA-Z0-9@:%_\\+.~#?&//=]*)?$')]],
      price: ['', [Validators.required, Validators.pattern(/\-?\d*\.?\d{1,2}/)]],
      hauteur: ['', [Validators.pattern(/\-?\d*\.?\d{1,2}/)]],
      largeur: ['', [Validators.pattern(/\-?\d*\.?\d{1,2}/)]],
      profondeur: ['', [Validators.pattern(/\-?\d*\.?\d{1,2}/)]],
    });

  }

  ngOnInit(): void {

    if (this.product) {
      this.form.patchValue({
        name: this.product.name,
        marque: this.product.marque,
        reference: this.product.reference,
        link: this.product.link,
        price: this.product.price,
        hauteur: this.product.hauteur,
        largeur: this.product.largeur,
        profondeur: this.product.profondeur,
      });
    }


  }

  get f() {
    return this.form.controls;
  }

  getProduct(id) {
    this.ws.get('products/' + id)
      .subscribe(
        data => {
          this.product = data;
          this.form.patchValue({
            name: this.product.name,
            marque: this.product.marque,
            reference: this.product.reference,
            link: this.product.link,
            price: this.product.price,
            hauteur: this.product.hauteur,
            largeur: this.product.largeur,
            profondeur: this.product.profondeur,
          });
          this.previewUrlProduct = this.product.photo;
        }
      )
  }

  post() {
    this.submitEditForm = true;
    const formData = new FormData();
    if (this.fileDataProduct) {
      formData.append('photo', this.fileDataProduct);
    }
    if (this.form.invalid) {
      return;
    }
    for (const [key, value] of Object.entries(this.form.value)) {
      if (value) {
        formData.append(key, value.toString());
      }
    }

    this.ws.post('products/update/' + this.productId, formData)
      .subscribe(
        data => {
          this.modalService.dismissAll('done');
          this.previewUrlProduct = '';
          Swal.fire({
            title: 'Produit modifié.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok',
          });
        }
      )
  }

  fileProgressProduct(fileInput: any) {
    this.fileDataProduct = <File>fileInput.target.files[0];
    this.previewProduct();
  }

  previewProduct() {
    // Show preview
    var mimeType = this.fileDataProduct.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileDataProduct);
    reader.onload = (_event) => {
      this.previewUrlProduct = reader.result;
    }
  }
}
