import { Component, OnInit } from "@angular/core";
import { WS } from "src/app/core/services/ws.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import Swal from "sweetalert2";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-coupons",
  templateUrl: "./coupons.component.html",
  styleUrls: ["./coupons.component.scss"],
})
export class CouponsComponent implements OnInit {
  coupons: any[];
  term: string;
  form: FormGroup;
  submit: boolean;
  randomC: any;
  users: any;
  date: any;
  error = "";

  constructor(
    public ws: WS,
    private modalService: NgbModal,
    public formBuilder: FormBuilder,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    const today = new Date();
    this.date = new Date();
    this.date.setDate(today.getDate() + 1);
    this.date.toISOString().slice(0, 10);
    this.getUsers();
    this.getCoupons();

    this.form = this.formBuilder.group({
      code: ["", [Validators.required]],
      created_for: ["", [Validators.required]],
      usage: ["multiple", [Validators.required]],
      expire_date: ["", []],
      amount: ["", [Validators.required, Validators.pattern("[0-9]+")]],
      min_buy: ["", [Validators.pattern("[0-9]+")]],
    });
  }
  get f() {
    return this.form.controls;
  }
  getCoupons() {
    this.ws.get("coupons").subscribe((data) => {
      this.coupons = data;
    });
  }

  getUsers() {
    this.ws.get("customers").subscribe((data) => {
      this.users = data;
    });
  }

  modal(Data: any) {
    this.randomC = this.randomCode();
    this.modalService.open(Data, { centered: true, size: "lg" });
  }

  deleteCoupon(id): void {
    Swal.fire({
      title: "Supprimer ce bon ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Supprimer",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.value) {
        this.ws.delete("coupons/" + id).subscribe((data) => {
          this.getCoupons();
        });
      }
    });
  }

  expireCoupon(id): void {
    Swal.fire({
      title: "Désactiver ce bon ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Désactiver",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.value) {
        this.ws.post("coupons/status/" + id, {}).subscribe(
          (data) => {
            this.getCoupons();
          },
          (error) => {
            // this.openErrorModal(error);
          }
        );
      }
    });
  }
  activateCoupon(id): void {
    Swal.fire({
      title: "Êtes-vous sûr d'activer ce bon?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Activer",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.value) {
        this.ws.post("coupons/status/" + id, {}).subscribe(
          (data) => {
            this.getCoupons();
          },
          (error) => {
            // this.openErrorModal(error);
          }
        );
      }
    });
  }
  post() {
    const formData = new FormData();

    for (const [key, value] of Object.entries(this.form.value)) {
      formData.append(key, value.toString() ?? null);
    }

    this.submit = true;
    this.ws.post("coupons", formData).subscribe(
      (data) => {
        this.getCoupons();
        this.modalService.dismissAll();
        this.form.reset();
      },
      (error) => {
        this.randomC = this.randomCode();
        if (error == "Code existant.") {
          this.form.reset();
          this.randomC = this.randomCode();
          // this.toastr.error('Code existant, veuillez utiliser un autre code');
        }
      }
    );
  }

  randomCode() {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  exportExcel() {
    this.ws.get("coupons/export").subscribe((data) => {
      const blob = new Blob([data], { type: 'application/octet-stream' });
      const url= window.URL.createObjectURL(blob);
      window.open(url);
    });
  }
}
