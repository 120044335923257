<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        <div class="text-sm-left d-none d-sm-block">
          <a href="/assets/Koachy_Politique_de_confidentialite.pdf" target="_blank">Politique de confidentialité</a>
          <!-- <p>Politique de confidentialité</p> -->
        </div>
      </div>
      <div class="col-sm-6">
        <div class="text-sm-right d-none d-sm-block">
          <a href="/assets/Koachy_CGVU.pdf" target="_blank">Conditions générales de vente et d'utilisation</a>
          <!-- <p>Conditions générales de vente et d'utilisation</p> -->
        </div>
      </div>
    </div>
  </div>
</footer>
