<div class="container-fluid">
  <app-pagetitle title="Bons de réduction"></app-pagetitle>

  <div class="row">

    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="text-right mb-4">
            <button (click)="modal(addCoupon)" class="btn btn-info jet-font">
              <i class="fas fa-tag"></i>
              Ajouter un bon de réduction</button>
          </div>
          <div class="dropdown float-right pt-2" ngbDropdown>
            <a href="javascript: void(0);" class="dropdown-toggle arrow-none card-drop" ngbDropdownToggle
              data-toggle="dropdown" aria-expanded="false">
              <i class="mdi mdi-dots-vertical"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
              <button (click)="exportExcel()" class="dropdown-item">Exporter</button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div id="tickets-table_filter" class="dataTables_filter text-md-right"><label
                  class="d-inline-flex align-items-center">Rechercher:
                  <input type="text" name="searchTerm" class="form-control form-control-sm ml-2"
                    aria-controls="tickets-table" [(ngModel)]="term" /></label></div>
            </div>
          </div>
          <div class="table-responsive">
            <!-- <div class="spinner-border text-info m-1" role="status">
                <span class="sr-only">Loading...</span>
              </div> -->
            <table class="table table-centered">
              <thead class="thead-light">
                <tr>
                  <th>Id</th>
                  <th>Code</th>
                  <th>Date d'émission</th>
                  <th>Date limite de validité</th>
                  <th>Bénéficiare</th>
                  <th>Utilisation</th>
                  <th>Montant</th>
                  <th>Solde</th>
                  <th>Statut</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of coupons |filter:term" [attr.id]="item.id">
                  <td>{{item.id}}</td>
                  <td>{{item.code}}</td>
                  <td>{{item.created_at  | date:'dd-MM-yyyy'}}</td>
                  <td *ngIf="item.expire_date">{{item.expire_date | date:'dd-MM-yyyy'}}</td>
                  <td *ngIf="!(item.expire_date)">Illimité</td>
                  <td>{{item.user_id == 0 ? 'Public' : item.user.firstname +" "+ item.user.lastname }}</td>
                  <td style="text-transform: capitalize;">{{item.usage}}</td>
                  <td>{{item.amount}}</td>
                  <td>{{item.remaining_amount}}</td>
                  <td style="text-transform: capitalize;">{{item.status}}</td>
                  <td>
                    <div (click)="deleteCoupon( item.id )" class="btn btn-sm btn-rounded btn-danger"> <i
                        class="fa fa-trash"></i></div>
                    <div *ngIf="item.status === 'valide'" (click)="expireCoupon( item.id )"
                      class="ml-2 btn btn-sm btn-rounded btn-secondary"> <i class="fa fa-lock p-0"></i></div>
                    <div *ngIf="item.status != 'valide'" (click)="activateCoupon( item.id )"
                      class="ml-2 btn btn-sm btn-rounded btn-secondary"> <i class="fa fa-lock-open p-0"></i></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<ng-template #addCoupon let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Ajouter un bon de réduction</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
  </div>
  <div class="modal-body">
    <form class="needs-validation" (ngSubmit)="post()" [formGroup]="form">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">Code *</label>
            <input type="text" [(ngModel)]="this.randomC" class="form-control" formControlName="code"
              [ngClass]="{'is-invalid': submit && f.code.errors}" placeholder=" " />
            <div *ngIf="submit && f.code.errors" class="invalid-feedback">
              <span *ngIf="f.code.errors.required">Champ obligatoire.</span>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <label for="">Utilisateur *</label>
          <select class="form-control" name="created_for" formControlName="created_for">
            <option value="0">Public</option>
            <option value="{{user.id}}" *ngFor="let user of users">
              {{ user.firstname}} {{ user.lastname}} ( {{user.email}} )
            </option>
          </select>
          <div *ngIf="submit && f.created_for.errors" class="invalid-feedback">
            <span *ngIf="f.created_for.errors.required">Champ obligatoire.</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">Montant *</label>
            <input type="number" min="0" step="any" class="form-control" formControlName="amount"
              [ngClass]="{'is-invalid': submit && f.amount.errors}" placeholder="Montant" />
            <div *ngIf="submit && f.amount.errors" class="invalid-feedback">
              <span *ngIf="f.amount.errors.required">Champ obligatoire.</span>
              <span *ngIf="f.amount.errors.pattern">Numéro non valide.</span>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">Minimum d'achats</label>
            <input type="number" min="0" step="any" class="form-control" formControlName="min_buy"
              [ngClass]="{'is-invalid': submit && f.min_buy.errors}" placeholder="Minimum d'achat" />
            <div *ngIf="submit && f.min_buy.errors" class="invalid-feedback">
              <span *ngIf="f.min_buy.errors.pattern">Numéro non valide.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">Date d'expiration</label>
            <input class="form-control" type="date" min="{{date | date:'yyyy-MM-dd'}}" name="expire_date"
              formControlName="expire_date">
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">Utilisation *</label>
            <div class="mt-2">
              <label for="multiple">Multiple</label>
              <input class="ml-2" type="radio" value="multiple" name="usage" formControlName="usage"
                [ngClass]="{'is-invalid': submit && f.usage.errors}">
              <label class="ml-4" for="unique">Unique</label>
              <input class="ml-2" type="radio" value="unique" name="usage" formControlName="usage"
                [ngClass]="{'is-invalid': submit && f.usage.errors}">
            </div>
            <div *ngIf="submit && f.usage.errors" class="invalid-feedback">
              <span *ngIf="f.usage.errors.required">Champ obligatoire.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="text-right mt-2">
        <button class="btn btn-primary jet-font" type="submit" [disabled]="form.invalid">
          <i class="fas fa-plus"></i>
          Ajouter</button>
      </div>
    </form>
  </div>
</ng-template>
