import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import bsCustomFileInput from 'bs-custom-file-input';
import { WS } from 'src/app/core/services/ws.service';
import { AuthfakeauthenticationService } from 'src/app/core/services/authfake.service';
import { EditProductModalComponent } from '../../../../layouts/shared/edit-product-modal/edit-product-modal.component';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsProjectAdminComponent implements OnInit {

  breadCrumbItems: Array<{}>;
  project;
  term;
  description;

  form: FormGroup;
  submit: boolean;
  project_id: number;

  fileData: File = null;
  fileDataProduct: File = null;
  fileDataUpload: File = null;
  previewUrl: any = null;
  previewUrlProduct: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  showSections = [true];
  user: any;
  sectionId = 0;
  headerTab = 0;
  settings: any = {};
  light: any;
  visitURL: any = null;
  designer: any;
  isDescDisabled: boolean;
  isVisitDisabled: boolean;

  constructor(
    private route: ActivatedRoute,
    public ws: WS,
    private modalService: NgbModal,
    public formBuilder: FormBuilder,
    private authFackservice: AuthfakeauthenticationService,
    private domSanitizer: DomSanitizer
  ) { }


  ngOnInit(): void {
    this.user = this.authFackservice.currentUserValue;

    bsCustomFileInput.init();
    this.breadCrumbItems = [{ label: 'Dashboard' }, { label: 'Utilisateurs', active: true }];


    this.route.params.subscribe((params) => {
      this.project_id = params.id;
      this.getProjectById(params.id); 
    });
    this.getSettings();

    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      marque: ['', [Validators.required]],
      reference: ['', []],
      link: ['', [Validators.required, Validators.pattern('^(https?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.)[a-zA-Z0-9-]+(\\.[a-zA-Z]{2,})(\\/[-a-zA-Z0-9@:%_\\+.~#?&//=]*)?$')]],
      price: ['', [Validators.required, Validators.pattern(/\-?\d*\.?\d{1,2}/)]],
      hauteur: ['', [ Validators.pattern(/\-?\d*\.?\d{1,2}/)]],
      largeur: ['', [ Validators.pattern(/\-?\d*\.?\d{1,2}/)]],
      profondeur: ['', [ Validators.pattern(/\-?\d*\.?\d{1,2}/)]],
      photo: ['', [Validators.required]],
    });

  }

  getProjectById(id): void {
    this.ws.get('projects/' + id)
      .subscribe(
        data => {
          this.project = data;
          if (this.project.visit) {
            this.isVisitDisabled = true;
            this.visitURL = this.getURL();
          } else {
            this.isVisitDisabled = false;
            this.visitURL = null;
          }
          if (this.project.designer_id){
            this.getDesigner(this.project.designer_id)
          } else {
            this.getDesigner(-1);
          }
          if (this.project.response_description) {
            this.isDescDisabled = true;
          } else {
            this.isDescDisabled = false;
          }
          
        }
      )
  }

  getURL(): any {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(this.project.visit);
  }

  getSettings() {
    this.ws.get('settings')
      .subscribe(
        data => {
          this.settings = data;
          this.light = this.lightOrDark(this.settings.primary_color);
        }
      );
  }

  getResponse(id) {
    if (this.project.quiz_respnses && this.project.quiz_respnses.responses.filter(o => o.id === id)[0]) {
      return this.project.quiz_respnses.responses.filter(o => o.id === id)[0];
    } else {
      return {};
    }
  }

  getDesigner(id) {
    this.ws.get('customers/find/' + id)
      .subscribe(
        data => {
          this.designer = data;
        }
      );
  }

  modal(Data: any) {
    this.modalService.open(Data, { centered: true, size: 'lg' });
  }

  get f() {
    return this.form.controls;
  }

  post() {
    this.submit = true;
    const formData = new FormData();
    formData.append('photo', this.fileDataProduct);

    if (this.form.invalid) {
      return;
    }
    
    for (const [key, value] of Object.entries(this.form.value)) {
      if (value) {
        formData.append(key, value.toString());
      }
    }
    formData.append('project_id', this.project_id.toString());

    this.ws.post('products', formData)
      .subscribe(
        data => {
          this.modalService.dismissAll();
          this.getProjectById(this.project_id);
          this.previewUrlProduct = '';
          this.form.reset();
          this.submit = false;
          Swal.fire({
            title: 'Produit ajouté.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok',
          });
        },
        error => {
          this.modalService.dismissAll();
          this.form.reset();
          this.submit = false;
          Swal.fire({
            title: 'Erreur serveur, veuillez réessayer.',
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: 'Ok',
          });
        }
      )
  }

  deleteProduct(id): void {
    Swal.fire({
      title: 'Êtes-vous sûr de supprimer ce produit?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        this.ws.delete('products/' + id)
          .subscribe((data: any) => {
            this.getProjectById(this.project_id);
            // this.toastr.success('Produit supprimé avec succés');
          })

      }
    })
  }

  deletePhoto(id): void {
    Swal.fire({
      title: 'Êtes-vous sûr de supprimer cette photo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        this.ws.delete('projects/' + this.project_id + '/photo/' + id)
          .subscribe((data: any) => {
            this.getProjectById(this.project_id);
          })

      }
    })
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }

  preview() {
    // Show preview
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
      this.savePhoto();
    }
  }

  savePhoto() {
    const formData = new FormData();
    formData.append('photo', this.fileData);

    this.ws.post('projects/' + this.project.id + '/photos', formData)
      .subscribe(
        data => {
          this.getProjectById(this.project_id);
          this.previewUrl = '';
        }
      )
  }

  saveDescription() {
    if (this.isDescDisabled) {
      this.isDescDisabled = false;
    } else {
      this.ws.put('projects/' + this.project.id, { response_description: this.project.response_description })
        .subscribe(
          data => {
            this.getProjectById(this.project_id);
            this.isDescDisabled = true;
            // this.toastr.success('Mise à jour avec succès');

          }
        )
    }
  }

  updateStatus(status) {
    this.ws.put('projects/update-status/' + this.project.id + '/' + status, {})
      .subscribe(
        data => {
          Swal.fire({
            title: 'Proposition envoyée.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok',
          });
          this.getProjectById(this.project_id);
          // this.toastr.success('Réponse envoyée avec succès');
        }
      )
  }

  updateFileProgress(fileInput: any , id) {
    this.fileDataUpload = <File>fileInput.target.files[0];
    this.updatePhoto(id);
  }

  updatePhoto(id) {
    const formData = new FormData();
    formData.append('photo', this.fileDataUpload);

    this.ws.post('projects/' + this.project.id + '/photo/' + id, formData)
      .subscribe(
        data => {
          this.getProjectById(this.project_id);
        }
      )
  }

  getMediaExtension(url) {
    return url.split(/[#?]/)[0].split('.').pop().trim();
  }

  getStatusName(status): string {
    return {
      'new': 'Nouveau',
      'draft': 'Brouillon',
      'sended': 'Envoyé',
      'in-progress': 'En cours',
      'pending': 'Vérification',
      'completed': 'Terminé'
    }[status];
  }


  fileProgressProduct(fileInput: any) {
    this.fileDataProduct = <File>fileInput.target.files[0];
    this.previewProduct();
  }

  previewProduct() {
    // Show preview
    var mimeType = this.fileDataProduct.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileDataProduct);
    reader.onload = (_event) => {
      this.previewUrlProduct = reader.result;
    }
  }

  editProduct(productId: number) {
    this.ws.get('products/' + productId)
      .subscribe(
        data => {
          let modalRef = this.modalService.open(EditProductModalComponent, { size: 'lg', centered: true, backdrop: 'static', keyboard: false });
          modalRef.componentInstance.productId = productId;
          modalRef.componentInstance.product = data;
          modalRef.componentInstance.previewUrlProduct = data.photo;
          modalRef.result.then(
            (result) => {

            },
            (reason) => {
              this.getProjectById(this.project_id);
            }
          );
        }
      )
  }

  lightOrDark(color: any) {
    var r: number, g: number, b: number, hsp: number;
    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {

      // If HEX --> store the red, green, blue values in separate variables
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

      r = color[1];
      g = color[2];
      b = color[3];
    }
    else {

      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      color = +("0x" + color.slice(1).replace(
        color.length < 5 && /./g, '$&$&'
      )
      );

      r = color >> 16;
      g = color >> 8 & 255;
      b = color & 255;
    }

    // HSP equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {

      return true;
    }
    else {

      return false;
    }
  }

  selectTab(sectionId: number): void {
    this.sectionId = sectionId;
  }

  selectHeader(headerTab: number): void {
    this.headerTab = headerTab;
  }

  checkUrl(url: any): boolean {
    if (url == "") {
      return true;
    }
    const isValidUrl = string => {
      let url;
      try {
        url = new URL(string);
      }
      catch (e) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    }
    return isValidUrl(url);
  }

  saveVisit() {
    if (this.isVisitDisabled) {
      this.isVisitDisabled = false;
    } else {
      if (!this.checkUrl(this.project.visit)) {
        Swal.fire({
          title: 'Veuillez introduire une URL valide.',
          icon: 'error',
          confirmButtonText: 'Ok',
        }).then((result) => {
          this.getProjectById(this.project_id);
          return;
        })
      } else {
        this.ws.put('projects/' + this.project.id, { visit: this.project.visit })
          .subscribe(
            data => {
              this.getProjectById(this.project_id);
              this.isVisitDisabled = true;
            }
          )
      }
    }
  }
}